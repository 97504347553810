import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { getApiUrl } from '../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faUser, 
    faEnvelope, 
    faPhone, 
    faSignOutAlt,
    faBuilding
} from '@fortawesome/free-solid-svg-icons';
import './ProfilePage.css';
import { AuthContext } from '../AuthContext';

const ProfilePage = ({ history }) => {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { logout } = useContext(AuthContext);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const token = localStorage.getItem('token');
                const userData = localStorage.getItem('user');

                if (!token || !userData) {
                    console.log('No token or user data found, redirecting to login');
                    localStorage.clear(); // Clear any partial data
                    history.push('/login');
                    return;
                }

                // First set profile from localStorage
                const userProfile = JSON.parse(userData);
                setProfile(userProfile);
                setLoading(false);

                // Then fetch latest data from server
                const response = await axios.get(getApiUrl('profile'), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                });

                if (response.data.success) {
                    setProfile(response.data.data);
                    // Update localStorage with latest data
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                } else {
                    throw new Error(response.data.message || 'Failed to fetch profile data');
                }
            } catch (err) {
                console.error('Profile fetch error:', err);
                
                // Handle unauthorized or token expired
                if (err.response && (err.response.status === 401 || err.response.status === 403)) {
                    console.log('Token expired or invalid, redirecting to login');
                    localStorage.clear();
                    history.push('/login');
                    return;
                }
                
                setError(err.response?.data?.message || err.message || 'Failed to load profile');
                setLoading(false);
            }
        };

        fetchProfile();
    }, [history]);

    const handleLogout = () => {
        logout(); // This will clear localStorage
        history.push('/'); // Redirect to home page
    };

    if (loading) return <div className="profile-loading">Loading...</div>;
    if (error) return <div className="profile-error">{error}</div>;
    if (!profile) return <div className="profile-error">No profile data found</div>;

    return (
        <div className="profile-container">
            <div className="profile-card">
                <div className="profile-header">
                    <div className="profile-avatar">
                        <FontAwesomeIcon icon={faUser} size="3x" />
                    </div>
                    <h1>{profile.name}</h1>
                </div>

                <div className="profile-details">
                    <div className="profile-info-item">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <div>
                            <h3>Email</h3>
                            <p>{profile.email}</p>
                        </div>
                    </div>

                    {profile.phone && (
                        <div className="profile-info-item">
                            <FontAwesomeIcon icon={faPhone} />
                            <div>
                                <h3>Phone</h3>
                                <p>{profile.phone}</p>
                            </div>
                        </div>
                    )}

                    {profile.company && (
                        <div className="profile-info-item">
                            <FontAwesomeIcon icon={faBuilding} />
                            <div>
                                <h3>Company</h3>
                                <p>{profile.company}</p>
                            </div>
                        </div>
                    )}
                </div>

                <button className="logout-button" onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Logout
                </button>
            </div>
        </div>
    );
};

export default withRouter(ProfilePage);
