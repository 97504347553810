import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faChartLine,
    faBullseye,
    faUsers,
    faPercentage,
    faMoneyBillWave,
    faClock,
    faChartPie,
    faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import './CampaignPackages.css';

const CampaignPackages = ({ packages = [], onSelectPackage }) => {
    const [selectedPackage, setSelectedPackage] = useState(null);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0
        }).format(amount);
    };

    const formatMetric = (value, suffix = '') => {
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}M${suffix}`;
        } else if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}K${suffix}`;
        }
        return `${value}${suffix}`;
    };

    const handlePackageSelect = (pkg) => {
        setSelectedPackage(pkg);
        onSelectPackage(pkg);
    };

    const getMediaTypeIcon = (type) => {
        switch (type) {
            case 'billboards':
                return faChartPie;
            case 'radio':
                return faUsers;
            case 'print':
                return faMoneyBillWave;
            case 'digital':
                return faChartLine;
            case 'social':
                return faUsers;
            case 'display':
                return faChartLine;
            default:
                return faBullseye;
        }
    };

    if (!packages || packages.length === 0) {
        return (
            <div className="no-packages">
                <p>Please select at least one media type and set your budget to view packages.</p>
            </div>
        );
    }

    return (
        <div className="packages-container">
            <h2>Choose Your Campaign Package</h2>
            <div className="packages-grid">
                {packages.map((pkg, index) => (
                    <div
                        key={index}
                        className={`package-card ${selectedPackage === pkg ? 'selected' : ''}`}
                        onClick={() => handlePackageSelect(pkg)}
                    >
                        <div className="package-header">
                            <h3>{pkg.name}</h3>
                            <div className="package-price">
                                <span className="price">{formatCurrency(pkg.totalCost)}</span>
                            </div>
                        </div>

                        <div className="package-content">
                            <div className="outcomes-section">
                                <h4>Estimated Outcomes</h4>
                                <div className="outcomes-grid">
                                    {pkg.metrics && (
                                        <>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faUsers} />
                                                <span className="outcome-label">Reach</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.reach || 0)}
                                                </span>
                                            </div>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faBullseye} />
                                                <span className="outcome-label">Engagement</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.engagement || 0)}
                                                </span>
                                            </div>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faPercentage} />
                                                <span className="outcome-label">Conversion</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.conversion || 0, '%')}
                                                </span>
                                            </div>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faChartLine} />
                                                <span className="outcome-label">ROI</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.roi || 0, '%')}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="media-allocations">
                                <h4>Media Allocations</h4>
                                <div className="allocations-list">
                                    {pkg.mediaAllocations.map((media, idx) => (
                                        <div key={idx} className="allocation-item">
                                            <div className="media-info">
                                                <FontAwesomeIcon icon={getMediaTypeIcon(media.type)} />
                                                <span className="media-type">
                                                    {media.type.charAt(0).toUpperCase() + media.type.slice(1)}
                                                </span>
                                                <span className="allocation-amount">
                                                    {formatCurrency(media.allocation)}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="features-section">
                                <h4>Features</h4>
                                <ul className="features-list">
                                    {pkg.features.map((feature, idx) => (
                                        <li key={idx}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                {pkg.IconComponent && (
                                    <div className="package-icon">
                                        <pkg.IconComponent />
                                    </div>
                                )}
                                <button className="select-package-btn">
                                    Select Package
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CampaignPackages;
