import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBrain,
    faLightbulb,
    faBullseye,
    faChartLine,
    faCalendarAlt,
    faEdit,
    faSpinner,
    faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { generatePackages } from '../utils/packageGenerator';
import CampaignPackages from '../components/CampaignPackages';
import './CampaignPlanning.css';

const CampaignPlanning = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        name: '',
        budget: 'medium',
        goal: 'awareness',
        duration: 30
    });
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [showAIAnimation, setShowAIAnimation] = useState(true);
    const [showPackages, setShowPackages] = useState(false);

    const budgetRanges = {
        small: { min: 100000, max: 500000, label: 'Small (₹1L - ₹5L)' },
        medium: { min: 500000, max: 2000000, label: 'Medium (₹5L - ₹20L)' },
        large: { min: 2000000, max: 10000000, label: 'Large (₹20L - ₹1Cr)' }
    };

    const campaignGoals = {
        awareness: { icon: faLightbulb, label: 'Brand Awareness' },
        leads: { icon: faBullseye, label: 'Lead Generation' },
        engagement: { icon: faChartLine, label: 'Engagement' }
    };

    useEffect(() => {
        // Simulate AI processing
        setTimeout(() => {
            setIsLoading(false);
            setShowAIAnimation(false);
            // Pre-fill form with example data
            setFormData(prev => ({
                ...prev,
                name: 'Q4 Marketing Campaign'
            }));
        }, 2000);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Simulate AI package generation
        setTimeout(() => {
            const budget = budgetRanges[formData.budget].min;
            const mediaTypes = ['digital', 'social', 'display'];
            
            const generatedPackages = generatePackages({
                budget,
                mediaTypes,
                goal: formData.goal
            });

            setIsLoading(false);
            history.push('/generated-campaigns', { 
                packages: generatedPackages,
                formData: {
                    ...formData,
                    budget: budgetRanges[formData.budget].label,
                    goal: campaignGoals[formData.goal].label
                }
            });
        }, 1500);
    };

    if (showAIAnimation) {
        return (
            <div className="campaign-planning-page">
                <div className="ai-loading-screen">
                    <FontAwesomeIcon icon={faBrain} className="ai-icon pulsing" />
                    <h2>AI Campaign Planner</h2>
                    <p>Initializing AI-powered campaign generation...</p>
                    <div className="loading-bar">
                        <div className="loading-progress"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="campaign-planning-page">
            <div className={`content-wrapper ${showPackages ? 'show-packages' : ''}`}>
                <div className="ai-campaign-form">
                    <h1>
                        <FontAwesomeIcon icon={faBrain} className="ai-icon" />
                        AI Campaign Planner
                    </h1>
                    <p className="ai-subtitle">Let our AI create the perfect campaign for you</p>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Campaign Name</label>
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Enter campaign name"
                                className="form-input"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Budget Range</label>
                            <div className="budget-options">
                                {Object.entries(budgetRanges).map(([key, { label }]) => (
                                    <label key={key} className="budget-option">
                                        <input
                                            type="radio"
                                            name="budget"
                                            value={key}
                                            checked={formData.budget === key}
                                            onChange={handleInputChange}
                                        />
                                        <span className="budget-label">{label}</span>
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Campaign Goal</label>
                            <div className="goal-options">
                                {Object.entries(campaignGoals).map(([key, { icon, label }]) => (
                                    <label key={key} className="goal-option">
                                        <input
                                            type="radio"
                                            name="goal"
                                            value={key}
                                            checked={formData.goal === key}
                                            onChange={handleInputChange}
                                        />
                                        <span className="goal-content">
                                            <FontAwesomeIcon icon={icon} />
                                            <span>{label}</span>
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Campaign Duration</label>
                            <div className="duration-input">
                                <input
                                    type="number"
                                    name="duration"
                                    value={formData.duration}
                                    onChange={handleInputChange}
                                    min="7"
                                    max="90"
                                    className="form-input"
                                    required
                                />
                                <span className="duration-suffix">Days</span>
                                <FontAwesomeIcon icon={faCalendarAlt} className="duration-icon" />
                            </div>
                        </div>

                        <button type="submit" className="submit-button" disabled={isLoading}>
                            {isLoading ? (
                                <>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                    Generating Campaigns...
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon={faBrain} />
                                    Generate AI Campaigns
                                </>
                            )}
                        </button>
                    </form>
                </div>

                <div className={`ai-generated-packages ${showPackages ? 'visible' : ''}`}>
                    <div className="packages-header">
                        <h2>AI-Generated Campaign Packages</h2>
                    </div>
                    <CampaignPackages
                        packages={packages}
                        onSelectPackage={setSelectedPackage}
                    />
                </div>
            </div>
        </div>
    );
};

export default CampaignPlanning;
