import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faFilter, 
    faSort, 
    faMapMarkerAlt, 
    faSearch,
    faUsers,
    faChartLine,
    faCalendarAlt,
    faTag
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { getApiUrl } from '../utils/api';
import './ViewSitesPage.css';

const MEDIA_TYPES = {
    'AIRLINE/AIRPORT': 'airport',
    'CINEMA': 'cinema',
    'DIGITAL': 'digital',
    'INFLUENCER': 'influencer',
    'MAGAZINE': 'magazine',
    'NEWSPAPER': 'newspaper',
    'BTL': 'btl',
    'OUTDOOR': 'outdoor',
    'RADIO': 'radio',
    'SPORTS': 'sports',
    'TELEVISION': 'tv'
};

function ViewSitesPage() {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const toolName = queryParams.get('toolName') || '';

    const [media, setMedia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 12,
        total: 0,
        pages: 0
    });
    const [filters, setFilters] = useState({
        toolName: toolName || '',
        search: '',
        sortBy: 'newest',
        priceRange: '',
        location: '',
        gender: '',
        age: '',
        income: '',
        businessNature: '',
        category: ''
    });

    const audienceFilters = {
        gender: [
            { label: 'All Genders', value: '' },
            { label: 'Primarily Male', value: 'male' },
            { label: 'Primarily Female', value: 'female' },
            { label: 'Balanced', value: 'balanced' }
        ],
        age: [
            { label: 'All Ages', value: '' },
            { label: 'Young (18-30)', value: '18-30' },
            { label: 'Middle Age (31-50)', value: '31-50' },
            { label: 'Senior (50+)', value: '50+' }
        ],
        income: [
            { label: 'All Income Levels', value: '' },
            { label: 'High Income', value: 'high' },
            { label: 'Middle Income', value: 'mid' },
            { label: 'Low Income', value: 'low' }
        ]
    };

    const priceRanges = [
        { label: 'All Prices', value: '' },
        { label: 'Under ₹10,000', value: '0-10000' },
        { label: '₹10,000 - ₹50,000', value: '10000-50000' },
        { label: '₹50,000 - ₹1,00,000', value: '50000-100000' },
        { label: 'Over ₹1,00,000', value: '100000-' }
    ];

    const sortOptions = [
        { label: 'Newest First', value: 'newest' },
        { label: 'Oldest First', value: 'oldest' },
        { label: 'Price: Low to High', value: 'price_asc' },
        { label: 'Price: High to Low', value: 'price_desc' },
        { label: 'Popularity', value: 'popularity' },
        { label: 'Reach: Highest First', value: 'reach_desc' },
        { label: 'Reach: Lowest First', value: 'reach_asc' }
    ];

    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    useEffect(() => {
        // Scroll to top when toolName changes
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [toolName]);

    const fetchMedia = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();
            
            // Add basic filters
            if (filters.toolName) queryParams.append('toolName', filters.toolName);
            if (filters.search) queryParams.append('search', filters.search);
            if (filters.priceRange) queryParams.append('priceRange', filters.priceRange);
            if (filters.location) queryParams.append('location', filters.location);
            
            // Add advanced filters
            if (filters.gender) queryParams.append('gender', filters.gender);
            if (filters.age) queryParams.append('age', filters.age);
            if (filters.income) queryParams.append('income', filters.income);
            if (filters.businessNature) queryParams.append('businessNature', filters.businessNature);
            if (filters.category) queryParams.append('category', filters.category);
            
            // Add sorting
            queryParams.append('sortBy', filters.sortBy);
            
            // Add pagination
            queryParams.append('page', pagination.page.toString());
            queryParams.append('limit', pagination.limit.toString());

            console.log('[ViewSitesPage] Fetching media with params:', queryParams.toString());
            
            const response = await axios.get(getApiUrl(`medias?${queryParams}`), {
                headers: {
                    'Accept': 'application/json'
                }
            });

            console.log('[ViewSitesPage] API Response:', response.data);

            if (response.data.success) {
                setMedia(response.data.data);
                setPagination(prev => ({
                    ...prev,
                    total: response.data.pagination.total,
                    pages: response.data.pagination.pages
                }));
            } else {
                throw new Error(response.data.error || 'Failed to fetch media');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Reset pagination when filters change
    useEffect(() => {
        setPagination(prev => ({
            ...prev,
            page: 1
        }));
    }, [filters]);

    // Fetch media when pagination or filters change
    useEffect(() => {
        fetchMedia();
    }, [pagination.page, filters]);

    useEffect(() => {
        if (toolName) {
            setFilters(prev => ({
                ...prev,
                toolName: toolName || ''
            }));
        }
    }, [toolName]);

    const handleFilterChange = (name, value) => {
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handlePageChange = (newPage) => {
        setPagination(prev => ({ ...prev, page: newPage }));
        // Scroll to top when changing pages
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const formatPrice = (amount) => {
        const formattedPrice = new Intl.NumberFormat('en-IN', {
            style: 'decimal',
            maximumFractionDigits: 0
        }).format(amount);
        return `₹${formattedPrice}`;
    };

    const getMetricIcon = (metricType) => {
        switch (metricType) {
            case 'views':
                return faChartLine;
            case 'followers':
                return faUsers;
            case 'duration':
                return faCalendarAlt;
            default:
                return faTag;
        }
    };

    const renderPagination = () => {
        const totalPages = pagination.pages;
        const currentPage = pagination.page;
        const pageNumbers = [];
        
        // Always show first page
        pageNumbers.push(1);
        
        // Calculate range of pages to show around current page
        let start = Math.max(2, currentPage - 2);
        let end = Math.min(totalPages - 1, currentPage + 2);
        
        // Add ellipsis after first page if needed
        if (start > 2) {
            pageNumbers.push('...');
        }
        
        // Add pages around current page
        for (let i = start; i <= end; i++) {
            pageNumbers.push(i);
        }
        
        // Add ellipsis before last page if needed
        if (end < totalPages - 1) {
            pageNumbers.push('...');
        }
        
        // Add last page if there is more than one page
        if (totalPages > 1) {
            pageNumbers.push(totalPages);
        }
        
        return (
            <div className="pagination-container">
                <button 
                    className="pagination-button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <div className="page-numbers">
                    {pageNumbers.map((number, index) => (
                        <button
                            key={index}
                            className={`page-number ${number === currentPage ? 'active' : ''} ${number === '...' ? 'ellipsis' : ''}`}
                            onClick={() => number !== '...' && handlePageChange(number)}
                            disabled={number === '...' || number === currentPage}
                        >
                            {number}
                        </button>
                    ))}
                </div>
                <button 
                    className="pagination-button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
        );
    };

    if (error) {
        return <div className="error-message">Error: {error}</div>;
    }

    return (
        <div className="view-sites-page">
            <div className="page-header">
                <h1>Discover Media Spaces</h1>
                <p className="subtitle">Find the perfect advertising space for your campaign</p>
            </div>

            <div className="filters-section">
                {/* Basic Filters */}
                <div className="basic-filters">
                    <div className="search-bar">
                        <FontAwesomeIcon icon={faSearch} size="sm" />
                        <input
                            type="text"
                            placeholder="Search media spaces..."
                            value={filters.search}
                            onChange={(e) => handleFilterChange('search', e.target.value)}
                        />
                    </div>

                    <div className="filter-group">
                        <select
                            value={filters.priceRange}
                            onChange={(e) => handleFilterChange('priceRange', e.target.value)}
                        >
                            {priceRanges.map(range => (
                                <option key={range.value} value={range.value}>
                                    {range.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="filter-group">
                        <select
                            value={filters.gender}
                            onChange={(e) => handleFilterChange('gender', e.target.value)}
                        >
                            {audienceFilters.gender.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="filter-group">
                        <input
                            type="text"
                            placeholder="Location..."
                            value={filters.location}
                            onChange={(e) => handleFilterChange('location', e.target.value)}
                        />
                    </div>

                    <div className="filter-group">
                        <select
                            value={filters.sortBy}
                            onChange={(e) => handleFilterChange('sortBy', e.target.value)}
                        >
                            {sortOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button 
                        className="advanced-filters-toggle"
                        onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
                    >
                        <FontAwesomeIcon icon={faFilter} />
                        {showAdvancedFilters ? 'Less Filters' : 'More Filters'}
                    </button>
                </div>

                {/* Advanced Filters */}
                {showAdvancedFilters && (
                    <div className="advanced-filters">
                        <div className="filter-row">
                            <div className="filter-group">
                                <label>Age Group</label>
                                <select
                                    value={filters.age}
                                    onChange={(e) => handleFilterChange('age', e.target.value)}
                                >
                                    {audienceFilters.age.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="filter-group">
                                <label>Income Level</label>
                                <select
                                    value={filters.income}
                                    onChange={(e) => handleFilterChange('income', e.target.value)}
                                >
                                    {audienceFilters.income.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="filter-group">
                                <label>Business Nature</label>
                                <input
                                    type="text"
                                    placeholder="Type of business..."
                                    value={filters.businessNature}
                                    onChange={(e) => handleFilterChange('businessNature', e.target.value)}
                                />
                            </div>

                            <div className="filter-group">
                                <label>Category</label>
                                <input
                                    type="text"
                                    placeholder="Media category..."
                                    value={filters.category}
                                    onChange={(e) => handleFilterChange('category', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Pagination at the top */}
            {pagination.pages > 1 && (
                <div className="top-pagination">
                    {renderPagination()}
                </div>
            )}

            {loading ? (
                <div className="loading-spinner">Loading...</div>
            ) : (
                <>
                    <div className="results-summary">
                        Found {pagination.total} media spaces
                    </div>
                    
                    <div className="media-grid">
                        {media.map((item) => (
                            <div key={item._id} className="media-card">
                                <div className="media-image">
                                    <img src={item.logo || 'https://via.placeholder.com/300x200'} alt={item.name} />
                                    <div className="media-type-badge">
                                        {item.toolName}
                                    </div>
                                </div>
                                <div className="media-content">
                                    <h3>{item.name}</h3>
                                    <div className="media-metrics">
                                        {item.locality && (
                                            <div className="metric">
                                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                                <span>{item.locality}</span>
                                            </div>
                                        )}
                                        {item.reach && (
                                            <div className="metric">
                                                <FontAwesomeIcon icon={faUsers} />
                                                <span>{new Intl.NumberFormat('en-IN').format(item.reach)} reach</span>
                                            </div>
                                        )}
                                        {item.duration && (
                                            <div className="metric">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                                <span>{item.duration}</span>
                                            </div>
                                        )}
                                    </div>
                                    {item.minimumBilling && (
                                        <div className="price-tag">
                                            <span>{formatPrice(item.minimumBilling)}</span>
                                        </div>
                                    )}
                                    <button 
                                        className="view-details-btn"
                                        onClick={() => history.push(`/media/${item._id}`)}
                                    >
                                        View Details
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="pagination">
                        <button
                            className="pagination-btn"
                            disabled={pagination.page === 1}
                            onClick={() => handlePageChange(pagination.page - 1)}
                        >
                            Previous
                        </button>
                        <div className="pagination-info">
                            Page <span className="current-page">{pagination.page}</span> of {pagination.pages}
                        </div>
                        <button
                            className="pagination-btn"
                            disabled={pagination.page === pagination.pages}
                            onClick={() => handlePageChange(pagination.page + 1)}
                        >
                            Next
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default ViewSitesPage;
