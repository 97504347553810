import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faPlane, 
  faUsers, 
  faFileAlt,
  faQuestionCircle,
  faMapMarkerAlt,
  faCalendarAlt,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
import { getApiUrl } from '../utils/api';
import './MediaDetailPage.css';

const MediaDetailPage = () => {
  const { id } = useParams();
  const [mediaDetail, setMediaDetail] = useState(null);
  const [selectedMediaOption, setSelectedMediaOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Icon mapping object
  const ICON_MAP = {
    'SeatPassenger': faUser,
    'Airplane': faPlane,
    'Group': faUsers,
    'File': faFileAlt,
    'Question': faQuestionCircle,
    'Location': faMapMarkerAlt,
    'Calendar': faCalendarAlt,
    'Stats': faChartLine
  };

  // Helper function to get icon
  const getIcon = (iconName) => {
    return ICON_MAP[iconName] || faFileAlt; // Default to file icon if not found
  };

  useEffect(() => {
    const fetchMediaDetails = async () => {
      if (!id) {
        setError('Media ID is required');
        setLoading(false);
        return;
      }

      console.log(`[MediaDetailPage] Fetching details for media ID: ${id}`);
      setLoading(true);
      setError(null);
      
      try {
        const response = await fetch(getApiUrl(`medias/${id}`));
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        console.log('[MediaDetailPage] Fetched data:', data);
        
        if (data.success) {
          setMediaDetail(data.data);
        } else {
          throw new Error(data.message || 'Failed to fetch media details');
        }
      } catch (err) {
        console.error('[MediaDetailPage] Error:', err);
        setError(err.message || 'Failed to fetch media details');
      } finally {
        setLoading(false);
      }
    };

    fetchMediaDetails();
  }, [id]);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!mediaDetail) return <div className="error">No media details found</div>;

  const renderValue = (value) => {
    if (value === null || value === undefined) return 'N/A';
    if (typeof value === 'object') {
      if (value.name) return value.name;
      if (value.value) return value.value;
      return JSON.stringify(value);
    }
    if (typeof value === 'number') {
      return new Intl.NumberFormat('en-IN').format(value);
    }
    return value.toString();
  };

  console.log(`[MediaDetailPage] Rendering media detail page for:`, {
    id: mediaDetail._id,
    name: mediaDetail.name,
    city: mediaDetail.city
  });

  return (
    <div className="media-detail-page">
      {/* Header Section */}
      <div className="header-section">
        <div className="media-header">
          <div className="media-title">
            <h1>{renderValue(mediaDetail.name)}</h1>
            <div className="location">
              <FontAwesomeIcon icon={getIcon('Location')} />
              <span>
                {renderValue(mediaDetail.city || mediaDetail.locality)}
                {mediaDetail.details?.geography?.[0]?.state && 
                  `, ${renderValue(mediaDetail.details.geography[0].state)}`}
              </span>
            </div>
          </div>
          <div className="media-logo">
            <img 
              src={mediaDetail.logo || 'https://via.placeholder.com/300x200'} 
              alt={mediaDetail.name || 'Media'} 
            />
          </div>
        </div>
      </div>

      {/* Key Attributes Section */}
      <div className="attributes-section">
        <h2>Key Statistics</h2>
        <div className="attributes-grid">
          {(mediaDetail.details?.attributes?.data || []).map((attr, index) => (
            <div key={index} className="attribute-card">
              {attr.icon && <FontAwesomeIcon icon={getIcon(attr.icon)} />}
              <div className="attribute-content">
                <div className="attribute-value">
                  {renderValue(attr.formatted_value || attr.value)}
                </div>
                <div className="attribute-name">{renderValue(attr.showName)}</div>
                {attr.toolTip && (
                  <div className="attribute-tooltip">{renderValue(attr.toolTip)}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Media Options Section */}
      <div className="media-options-section">
        <h2>Available Media Options</h2>
        <div className="options-grid">
          {(mediaDetail.details?.mediaOptions?.data || []).map((option, index) => (
            <div
              key={option._id || index}
              className={`option-card ${selectedMediaOption?._id === option._id ? 'selected' : ''}`}
              onClick={() => setSelectedMediaOption(option)}
            >
              <div className="option-header">
                <h3>{renderValue(option.name)}</h3>
                {option.type && <span className="option-type">{renderValue(option.type)}</span>}
              </div>
              {option.rates?.defaultRates?.minimumBilling && (
                <div className="option-price">
                  <div className="price-label">Starting from</div>
                  <div className="price-value">
                    ₹{renderValue(option.rates.defaultRates.minimumBilling)}
                  </div>
                </div>
              )}
              <div className="option-attributes">
                {(option.attributes || []).map((attr, attrIndex) => (
                  <div key={attrIndex} className="option-attribute">
                    <span className="attribute-label">{renderValue(attr.showName)}:</span>
                    <span className="attribute-value">
                      {renderValue(attr.formatted_value || attr.value)}
                      {attr.unit && ` ${attr.unit}`}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Specifications Section */}
      {mediaDetail.details?.specifications?.available && (
        <div className="specifications-section">
          <h2>Creative Specifications</h2>
          <div className="specifications-list">
            {(mediaDetail.details.specifications.data || []).map((spec, index) => (
              <div key={index} className="specification-item">
                <FontAwesomeIcon icon={getIcon('File')} />
                <div className="specification-content">
                  <h3>{renderValue(spec.name)}</h3>
                  <p>{renderValue(spec.description)}</p>
                  {spec.fields && (
                    <div className="spec-fields">
                      {spec.fields.map((field, fieldIndex) => (
                        <div key={fieldIndex} className="spec-field">
                          <span className="field-label">{renderValue(field.key)}:</span>
                          <span className="field-value">{renderValue(field.value)}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Reference Images Section */}
      {mediaDetail.details?.referenceImages?.available && (
        <div className="reference-images-section">
          <h2>Reference Images</h2>
          <div className="images-grid">
            {(mediaDetail.details.referenceImages.data || []).map((imageUrl, index) => (
              <div key={index} className="image-card">
                <img 
                  src={imageUrl || 'https://via.placeholder.com/300x200'} 
                  alt={`Reference ${index + 1}`}
                  onError={(e) => {
                    e.target.src = 'https://via.placeholder.com/300x200';
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Selected Media Option Details */}
      {selectedMediaOption && (
        <div className="selected-option-details">
          <h2>{renderValue(selectedMediaOption.name)} Details</h2>
          
          {/* Creative Specifications */}
          <div className="specs-section">
            <h3>
              <FontAwesomeIcon icon={getIcon('File')} />
              Creative Specifications
            </h3>
            {(selectedMediaOption.creativeSpecs || []).map((spec, index) => (
              <div key={index} className="spec-group">
                <h4>{renderValue(spec.name)}</h4>
                <div className="spec-fields">
                  {(spec.fields || []).map((field, fieldIndex) => (
                    <div key={fieldIndex} className="spec-field">
                      <span className="field-key">{renderValue(field.key)}:</span>
                      <span className="field-value">{renderValue(field.value)}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          {/* Reference Artworks */}
          {(selectedMediaOption.referenceArtworks || []).length > 0 && (
            <div className="artworks-section">
              <h3>
                <FontAwesomeIcon icon={getIcon('Question')} />
                Reference Artworks
              </h3>
              <div className="artworks-grid">
                {(selectedMediaOption.referenceArtworks || []).map((artwork, index) => (
                  <div key={index} className="artwork-card">
                    <img src={artwork.url || 'https://via.placeholder.com/300x200'} alt={renderValue(artwork.name)} />
                    {artwork.name && <div className="artwork-name">{renderValue(artwork.name)}</div>}
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Variable Pricing Units */}
          {(selectedMediaOption.variablePricingUnits || []).length > 0 && (
            <div className="pricing-units-section">
              <h3>
                <FontAwesomeIcon icon={getIcon('Stats')} />
                Pricing Options
              </h3>
              <div className="pricing-units-grid">
                {(selectedMediaOption.variablePricingUnits || []).map((unit, index) => (
                  <div key={index} className="pricing-unit">
                    <h4>{renderValue(unit.name)}</h4>
                    <select defaultValue={unit.defaultValue}>
                      {(unit.values || []).map((value, valueIndex) => (
                        <option key={valueIndex} value={value}>
                          {renderValue(value)}
                        </option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Stats Section */}
      {mediaDetail.stats && mediaDetail.stats.length > 0 && (
        <div className="stats-section">
          {mediaDetail.stats.map((statGroup, index) => (
            <div key={index} className="stat-group">
              {statGroup.heading && <h3>{renderValue(statGroup.heading)}</h3>}
              {statGroup.stats && (
                <div className="stats-grid">
                  {statGroup.stats.map((stat, statIndex) => (
                    <div key={statIndex} className="stat-item">
                      <span className="stat-name">{renderValue(stat.name)}</span>
                      <span className="stat-value">{renderValue(stat.value)}</span>
                      {stat.hyperLink && (
                        <a 
                          href={stat.hyperLink} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="stat-link"
                        >
                          Learn More
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Links Section */}
      {mediaDetail.links && (
        <div className="links-section">
          <h3>Related Links</h3>
          <div className="links-grid">
            {mediaDetail.links.map((link, index) => (
              <a 
                key={index} 
                href={link.hyperLink} 
                target="_blank" 
                rel="noopener noreferrer"
                className="link-item"
              >
                <span className="link-name">{renderValue(link.name)}</span>
                <span className="link-value">{renderValue(link.value)}</span>
              </a>
            ))}
          </div>
        </div>
      )}

      {/* About Section */}
      {(mediaDetail.about || mediaDetail.content) && (
        <div className="about-section">
          <h2>About {renderValue(mediaDetail.name)}</h2>
          {mediaDetail.about && <p>{renderValue(mediaDetail.about)}</p>}
          {mediaDetail.content && (
            <div className="content" dangerouslySetInnerHTML={{ __html: mediaDetail.content }} />
          )}
        </div>
      )}

      {/* Demographics Section */}
      {mediaDetail.demographics?.length > 0 && (
        <div className="demographics-section">
          <h2>Audience Demographics</h2>
          <div className="demographics-grid">
            {mediaDetail.demographics.map((demo, index) => (
              <div key={index} className="demographic-card">
                <h3>{renderValue(demo.name)}</h3>
                <div className="demographic-data">
                  {Object.entries(demo.data || {}).map(([key, value], i) => (
                    <div key={i} className="demographic-item">
                      <span className="item-label">{renderValue(key)}:</span>
                      <span className="item-value">{renderValue(value)}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* FAQs Section */}
      {mediaDetail.faqs?.length > 0 && (
        <div className="faqs-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faqs-list">
            {mediaDetail.faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <h3>{renderValue(faq.question)}</h3>
                <p>{renderValue(faq.answer)}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaDetailPage;
