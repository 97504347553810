const SPACE_TYPES = {
    AIRLINE_AIRPORT: 'AIRLINE/AIRPORT',
    CINEMA: 'CINEMA',
    DIGITAL: 'DIGITAL',
    INFLUENCER: 'INFLUENCER',
    MAGAZINE: 'MAGAZINE',
    NEWSPAPER: 'NEWSPAPER',
    BTL: 'BTL',
    OUTDOOR: 'OUTDOOR',
    RADIO: 'RADIO',
    SPORTS: 'SPORTS',
    TELEVISION: 'TELEVISION',
    AGENCY_PARTNER: 'AGENCY PARTNER'
};

const STATUS_TYPES = {
    PENDING: 'pending',
    ACTIVE: 'active',
    INACTIVE: 'inactive'
};

const AVAILABILITY_TYPES = {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly'
};

export {
    SPACE_TYPES,
    STATUS_TYPES,
    AVAILABILITY_TYPES
}; 