import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import CampaignPackages from '../components/CampaignPackages';
import './GeneratedCampaigns.css';

// Package illustrations as components
const BasicPackageIcon = () => (
    <svg width="200" height="150" viewBox="0 0 200 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="200" height="150" fill="#f8fafc"/>
        <circle cx="100" cy="75" r="40" fill="url(#basic-gradient)"/>
        <path d="M85 75L95 85L115 65" stroke="white" strokeWidth="4" strokeLinecap="round"/>
        <defs>
            <linearGradient id="basic-gradient" x1="0" y1="0" x2="200" y2="150" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4158D0"/>
                <stop offset="1" stopColor="#C850C0"/>
            </linearGradient>
        </defs>
    </svg>
);

const PremiumPackageIcon = () => (
    <svg width="200" height="150" viewBox="0 0 200 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="200" height="150" fill="#f8fafc"/>
        <path d="M60 100L100 40L140 100H60Z" fill="url(#premium-gradient)"/>
        <circle cx="100" cy="70" r="15" fill="white"/>
        <defs>
            <linearGradient id="premium-gradient" x1="60" y1="40" x2="140" y2="100" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4158D0"/>
                <stop offset="1" stopColor="#C850C0"/>
            </linearGradient>
        </defs>
    </svg>
);

const EnterprisePackageIcon = () => (
    <svg width="200" height="150" viewBox="0 0 200 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="200" height="150" fill="#f8fafc"/>
        <rect x="50" y="50" width="100" height="60" rx="8" fill="url(#enterprise-gradient)"/>
        <circle cx="100" cy="80" r="20" fill="white"/>
        <path d="M90 80L98 88L110 76" stroke="url(#enterprise-gradient)" strokeWidth="3" strokeLinecap="round"/>
        <defs>
            <linearGradient id="enterprise-gradient" x1="50" y1="50" x2="150" y2="110" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4158D0"/>
                <stop offset="1" stopColor="#C850C0"/>
            </linearGradient>
        </defs>
    </svg>
);

const packageIcons = {
    'Basic': BasicPackageIcon,
    'Premium': PremiumPackageIcon,
    'Enterprise': EnterprisePackageIcon
};

const GeneratedCampaigns = () => {
    const [loading, setLoading] = useState(true);
    const [packages, setPackages] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const { formData = {} } = location.state || {};

    useEffect(() => {
        if (!location.state || !formData.name) {
            history.replace('/campaign-planning');
            return;
        }

        if (!location.state?.packages) {
            history.push('/');
            return;
        }

        // Add icons to packages
        const packagesWithIcons = location.state.packages.map(pkg => ({
            ...pkg,
            IconComponent: packageIcons[pkg.name]
        }));

        // Simulate loading for animation
        setTimeout(() => {
            setPackages(packagesWithIcons);
            setLoading(false);
        }, 2000); // 2 seconds loading animation
    }, [location.state, history, formData]);

    const handlePackageSelect = (selectedPackage) => {
        // Handle package selection
        console.log('Selected package:', selectedPackage);
    };

    if (loading) {
        return (
            <div className="generated-campaigns-container">
                <div className="loading-container">
                    <div className="loading-animation">
                        <div className="circle"></div>
                        <div className="circle"></div>
                        <div className="circle"></div>
                    </div>
                    <h2>Generating Your AI-Powered Campaign Packages</h2>
                    <p>Our AI is crafting personalized marketing solutions for you...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="generated-campaigns-container">
            <div className="campaigns-header">
                <h1>AI-Generated Campaign Packages</h1>
                <p>Select the package that best fits your marketing goals</p>
            </div>
            <div className="campaign-summary">
                <div className="campaign-details">
                    <p><strong>Campaign Name:</strong> {formData.name}</p>
                    <p><strong>Budget Range:</strong> {formData.budget}</p>
                    <p><strong>Campaign Goal:</strong> {formData.goal}</p>
                    <p><strong>Duration:</strong> {formData.duration} days</p>
                </div>
            </div>
            <div className="packages-wrapper">
                {packages.length > 0 ? (
                    <CampaignPackages 
                        packages={packages}
                        onSelectPackage={handlePackageSelect}
                    />
                ) : (
                    <div className="no-packages">
                        <h3>No packages generated</h3>
                        <p>Please try again with different campaign settings.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GeneratedCampaigns;
