import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './LeadCaptureModal.css';

const LeadCaptureModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !name || !phone) {
            setError('Please fill in all fields');
            return;
        }

        // Basic phone number validation
        const phoneRegex = /^[\d\s+-]{10,15}$/;
        if (!phoneRegex.test(phone)) {
            setError('Please enter a valid phone number');
            return;
        }

        try {
            const apiUrl = process.env.NODE_ENV === 'production'
                ? '/api/leads'
                : 'http://localhost:5001/api/leads';

            const requestData = { 
                email, 
                name, 
                phone: phone.replace(/[\s-]/g, '') // Remove spaces and hyphens before sending
            };

            console.log('Sending lead data:', requestData);

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Server response:', errorData);
                setError(errorData.error || 'Something went wrong. Please try again.');
                return;
            }

            const data = await response.json();
            console.log('Success response:', data);
            setSubmitted(true);
            localStorage.setItem('leadCaptured', 'true');
            setTimeout(onClose, 3000);
        } catch (err) {
            console.error('Error submitting lead:', err);
            setError('Something went wrong. Please try again.');
        }
    };

    return (
        <div className="lead-capture-overlay" onClick={(e) => {
            if (e.target.className === 'lead-capture-overlay') {
                onClose();
            }
        }}>
            <div className="lead-capture-modal">
                <button className="close-button" onClick={onClose} aria-label="Close dialog">
                    <FontAwesomeIcon icon={faTimes} size="lg" className="close-icon" />
                </button>
                <div className="modal-content">
                    <FontAwesomeIcon icon={faEnvelope} className="envelope-icon" />
                    <h2>Stay Updated!</h2>
                    <p>Get exclusive access to the best advertising opportunities!</p>
                    {!submitted ? (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        placeholder="Your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        placeholder="Your Phone Number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        className="form-input"
                                        pattern="[\d\s+-]{10,15}"
                                        title="Please enter a valid phone number (10-15 digits, can include +, spaces, or hyphens)"
                                    />
                                </div>
                                {error && <div className="error-message">{error}</div>}
                                <button type="submit" className="submit-button">
                                    Get Access
                                </button>
                            </form>
                            <p className="privacy-note">
                                We respect your privacy. Unsubscribe at any time.
                            </p>
                        </>
                    ) : (
                        <div className="success-message">
                            <h2>Thank You!</h2>
                            <p>We'll be in touch with exclusive offers soon.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LeadCaptureModal;
