import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './AddSitePage.css';
import api from '../api';
import { SPACE_TYPES, AVAILABILITY_TYPES } from '../constants/adSpaceTypes';

function AddSitePage() {
    const history = useHistory();
    const [formData, setFormData] = useState({
        siteTitle: 'Times Square Billboard',
        description: 'Prime location digital billboard in the heart of Times Square, New York. Perfect for high-impact advertising campaigns with massive foot traffic exposure.',
        spaceType: SPACE_TYPES.OUTDOOR,
        adSlotAvailability: AVAILABILITY_TYPES.MONTHLY,
        address: {
            street: '1535 Broadway',
            city: 'New York',
            state: 'New York',
            country: 'United States'
        },
        contactInfo: {
            contactName: 'John Smith',
            email: 'john.smith@mediacompany.com',
            phoneNumber: '+1 (212) 555-0123',
            preferredContactMethod: ['Email']
        },
        pricing: {
            amount: '25000',
            currency: 'USD',
            period: AVAILABILITY_TYPES.MONTHLY
        },
        preferredAdvertisers: 'Luxury brands, Entertainment, Technology companies'
    });

    const [showDialog, setShowDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('.')) {
            const [parent, child] = name.split('.');
            setFormData(prev => ({
                ...prev,
                [parent]: {
                    ...prev[parent],
                    [child]: value
                }
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const token = localStorage.getItem('token');
        if (!token) {
            setErrorMessage('Please log in to create an ad space');
            setErrorDialog(true);
            return;
        }

        try {
            const response = await api.post('/adspaces/create', formData);
            if (response.data.success) {
                setShowDialog(true);
            }
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || 
                'An error occurred while creating the site'
            );
            setErrorDialog(true);
        }
    };

    return (
        <div className="add-site-page">
            <h2>Add Your Advertising Space</h2>
            <form onSubmit={handleSubmit} className="add-site-form">
                <section className="form-section">
                    <h3>Basic Information</h3>
                    
                    <div className="form-group">
                        <label>Site Title *</label>
                        <input
                            type="text"
                            name="siteTitle"
                            value={formData.siteTitle}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter site title"
                        />
                    </div>

                    <div className="form-group">
                        <label>Description *</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            required
                            placeholder="Describe your advertising space"
                        />
                    </div>

                    <div className="form-group">
                        <label>Space Type *</label>
                        <select
                            name="spaceType"
                            value={formData.spaceType}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Media Type</option>
                            {Object.entries(SPACE_TYPES).map(([key, value]) => (
                                <option key={key} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label>Availability *</label>
                        <select
                            name="adSlotAvailability"
                            value={formData.adSlotAvailability}
                            onChange={handleInputChange}
                            required
                        >
                            {Object.values(AVAILABILITY_TYPES).map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>
                </section>

                <section className="form-section">
                    <h3>Location</h3>
                    <div className="form-group">
                        <label>Street Address *</label>
                        <input
                            type="text"
                            name="address.street"
                            value={formData.address.street}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter street address"
                        />
                    </div>

                    <div className="form-group">
                        <label>City *</label>
                        <input
                            type="text"
                            name="address.city"
                            value={formData.address.city}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter city"
                        />
                    </div>

                    <div className="form-group">
                        <label>State *</label>
                        <input
                            type="text"
                            name="address.state"
                            value={formData.address.state}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter state"
                        />
                    </div>

                    <div className="form-group">
                        <label>Country *</label>
                        <input
                            type="text"
                            name="address.country"
                            value={formData.address.country}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter country"
                        />
                    </div>
                </section>

                <section className="form-section">
                    <h3>Pricing</h3>
                    <div className="form-group">
                        <label>Price Amount *</label>
                        <input
                            type="number"
                            name="pricing.amount"
                            value={formData.pricing.amount}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter price amount"
                        />
                    </div>
                </section>

                <section className="form-section">
                    <h3>Contact Information</h3>
                    <div className="form-group">
                        <label>Contact Name *</label>
                        <input
                            type="text"
                            name="contactInfo.contactName"
                            value={formData.contactInfo.contactName}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter contact name"
                        />
                    </div>

                    <div className="form-group">
                        <label>Email *</label>
                        <input
                            type="email"
                            name="contactInfo.email"
                            value={formData.contactInfo.email}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter email address"
                        />
                    </div>

                    <div className="form-group">
                        <label>Phone Number *</label>
                        <input
                            type="tel"
                            name="contactInfo.phoneNumber"
                            value={formData.contactInfo.phoneNumber}
                            onChange={handleInputChange}
                            required
                            placeholder="Enter phone number"
                        />
                    </div>
                </section>

                <button type="submit" className="submit-btn">
                    Create Ad Space
                </button>
            </form>

            {showDialog && (
                <div className="dialog-overlay">
                    <div className="dialog success">
                        <h3>Success!</h3>
                        <p>Your ad space has been created successfully.</p>
                        <button onClick={() => {
                            setShowDialog(false);
                            history.push('/view-sites');
                        }}>OK</button>
                    </div>
                </div>
            )}

            {errorDialog && (
                <div className="dialog-overlay">
                    <div className="dialog error">
                        <h3>Error</h3>
                        <p>{errorMessage}</p>
                        <button onClick={() => setErrorDialog(false)}>OK</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddSitePage;
