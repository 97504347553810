import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import HomePage from './pages/HomePage';
import LoginModal from './components/LoginModal';
import SignUpModal from './components/SignUpModal';
import PrivateRoute from './PrivateRoute';
import Header from './components/Header';
import ScrollToTop from './components/ScrollToTop';
import AddSitePage from './pages/AddSitePage';
import ViewSitesPage from './pages/ViewSitesPage';
import MediaDetailPage from './pages/MediaDetailPage';
import AgencyRegistration from './pages/AgencyRegistration';
import ProfilePage from './pages/ProfilePage';
import Sidebar from './components/Sidebar';
import ErrorBoundary from './components/ErrorBoundary';
import useExitIntent from './hooks/useExitIntent';
import LeadCaptureModal from './components/LeadCaptureModal';
import Footer from './components/Footer';
import ChatBot from './components/ChatBot';
import CampaignPlanning from './pages/CampaignPlanning';
import GeneratedCampaigns from './pages/GeneratedCampaigns';
import Analytics from './pages/Analytics';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import Sitemap from './pages/Sitemap';
import { initGA, pageview, trackSessionStart } from './utils/analytics';
import './App.css';

function AppContent() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showExitIntent, setShowExitIntent] = useExitIntent({ threshold: 20 });

  // Initialize analytics and session tracking
  useEffect(() => {
    initGA();
    trackSessionStart();
  }, []);

  // Track page views with detailed information
  useEffect(() => {
    const handleRouteChange = () => {
      pageview(location.pathname + location.search);
    };

    handleRouteChange(); // Track initial page load
  }, [location]);

  const toggleLoginModal = () => {
    setShowLoginModal(!showLoginModal);
    setShowSignUpModal(false);
  };

  const toggleSignUpModal = () => {
    setShowSignUpModal(!showSignUpModal);
    setShowLoginModal(false);
  };

  return (
    <ErrorBoundary>
      <AuthProvider>
        <div className="app">
          {showExitIntent && (
            <LeadCaptureModal onClose={() => setShowExitIntent(false)} />
          )}
          <Header 
            onLoginClick={toggleLoginModal} 
            isSidebarOpen={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
          <Sidebar 
            isSidebarOpen={isSidebarOpen} 
            setIsSidebarOpen={setIsSidebarOpen} 
          />
          <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
            <ErrorBoundary>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <HomePage 
                      onLoginClick={toggleLoginModal} 
                      isSidebarOpen={isSidebarOpen} 
                      setIsSidebarOpen={setIsSidebarOpen}
                    />
                  )}
                />
                <Route 
                  path="/agency-registration"
                  component={AgencyRegistration}
                />
                <PrivateRoute path="/add-site" component={AddSitePage} />
                <Route path="/view-sites" component={ViewSitesPage} />
                <Route path="/media/:id" component={MediaDetailPage} />
                <Route path="/campaign-planning" exact component={CampaignPlanning} />
                <Route path="/generated-campaigns" exact component={GeneratedCampaigns} />
                <Route path="/analytics" component={Analytics} />
                <PrivateRoute path="/profile" component={ProfilePage} />
                <Route path="/privacy-policy" component={PrivacyPolicy} />
                <Route path="/terms" component={TermsOfService} />
                <Route path="/sitemap" component={Sitemap} />
                <Route path="*" render={() => <h1>404 Not Found</h1>} />
              </Switch>
            </ErrorBoundary>
          </div>
          <Footer />
          <ChatBot />
          {showLoginModal && (
            <LoginModal
              onClose={toggleLoginModal}
              onSwitchToSignUp={toggleSignUpModal}
            />
          )}
          {showSignUpModal && (
            <SignUpModal
              onClose={toggleSignUpModal}
              onSwitchToLogin={toggleLoginModal}
            />
          )}
        </div>
      </AuthProvider>
    </ErrorBoundary>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppContent />
    </Router>
  );
}

export default App;
