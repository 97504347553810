import React, { useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faChartBar,
  faCalendarAlt,
  faBoxOpen,
  faUsers,
  faSignOutAlt,
  faPlusCircle,
  faTimes,
  faSearch,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';
import { AuthContext } from '../AuthContext';
import { SPACE_TYPES } from '../constants/adSpaceTypes';

function Sidebar({ isSidebarOpen, setIsSidebarOpen }) {
  const { user, logout } = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = () => {
    logout();
    setIsSidebarOpen(false);
    history.push('/');
  };

  return (
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <nav className="nav-menu">
          <Link 
            to="/" 
            className="nav-item" 
            onClick={() => setIsSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </Link>
          
          <Link 
            to="/find-media-rates" 
            className="nav-item" 
            onClick={() => setIsSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faChartBar} />
            <span>Find Media Rates</span>
          </Link>
          
          <Link 
            to="/plan-media-spend" 
            className="nav-item" 
            onClick={() => setIsSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span>Plan Media Spend</span>
          </Link>
          
          <Link 
            to="/buy-media-spots" 
            className="nav-item" 
            onClick={() => setIsSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faBoxOpen} />
            <span>Buy Media Spots</span>
          </Link>
          
          {/* View Sites - Always visible */}
          <Link 
            to="/view-sites" 
            className="nav-item" 
            onClick={() => setIsSidebarOpen(false)}
          >
            <FontAwesomeIcon icon={faEye} />
            <span>View Sites</span>
          </Link>

          {/* Protected items - Only visible when logged in */}
          {user && (
              <Link 
                to="/add-site" 
                className="nav-item" 
                onClick={() => setIsSidebarOpen(false)}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
                <span>Add Site</span>
              </Link>
          )}
        </nav>

        {/* Show Logout button only when logged in */}
        {user && (
          <div className="logout-section">
            <button className="logout-btn" onClick={handleLogout}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span>Logout</span>
            </button>
          </div>
        )}
      </div>
  );
}

export default Sidebar;
