import React from 'react';
import './LegalPages.css';

function TermsOfService() {
    return (
        <div className="legal-page">
            <div className="legal-container">
                <h1>Terms of Service</h1>
                <div className="last-updated">Last Updated: {new Date().toLocaleDateString()}</div>

                <section>
                    <h2>1. Acceptance of Terms</h2>
                    <p>By accessing and using PlaceAd's services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>
                </section>

                <section>
                    <h2>2. Service Description</h2>
                    <p>PlaceAd provides an AI-powered marketing campaign planning platform that enables:</p>
                    <ul>
                        <li>Campaign planning and optimization</li>
                        <li>Media space booking and management</li>
                        <li>Analytics and reporting</li>
                        <li>Agency partnership management</li>
                    </ul>
                </section>

                <section>
                    <h2>3. User Obligations</h2>
                    <p>As a user of our services, you agree to:</p>
                    <ul>
                        <li>Provide accurate and complete information</li>
                        <li>Maintain the security of your account</li>
                        <li>Comply with all applicable laws and regulations</li>
                        <li>Not misuse or abuse our services</li>
                    </ul>
                </section>

                <section>
                    <h2>4. Payment Terms</h2>
                    <p>For paid services:</p>
                    <ul>
                        <li>All fees are payable in advance</li>
                        <li>Refunds are subject to our refund policy</li>
                        <li>Late payments may result in service suspension</li>
                        <li>Prices are subject to change with notice</li>
                    </ul>
                </section>

                <section>
                    <h2>5. Intellectual Property</h2>
                    <p>All content and materials available on PlaceAd are protected by intellectual property rights. Users may not:</p>
                    <ul>
                        <li>Copy or reproduce our content without permission</li>
                        <li>Modify or create derivative works</li>
                        <li>Use our trademarks without authorization</li>
                    </ul>
                </section>

                <section>
                    <h2>6. Contact Information</h2>
                    <p>For questions about these Terms of Service, please contact us at:</p>
                    <div className="contact-details">
                        <p>Email: legal@placead.ai</p>
                        <p>Address: 123 Innovation Drive, San Francisco, CA 94105</p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TermsOfService;
