import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import './CampaignPlanner.css';

const CampaignPlanner = ({ onClose }) => {
  const history = useHistory();
  
  // Get default dates
  const today = new Date();
  const oneMonthFromNow = new Date(today);
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
  
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const [campaignDetails, setCampaignDetails] = useState({
    name: 'Q' + (Math.floor(today.getMonth() / 3) + 1) + ' Marketing Campaign',
    startDate: formatDate(today),
    endDate: formatDate(oneMonthFromNow),
    budget: '100000',
    targetAudience: 'Young professionals aged 25-35 in major metropolitan areas',
    objectives: 'Increase brand awareness and drive website traffic through targeted multi-channel advertising'
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Close the modal if it exists
    if (onClose) {
      onClose();
    }

    // Navigate to the campaign planner page with the campaign details
    history.push('/campaign-planner', { campaignDetails });
  };

  return (
    <div className="campaign-planner">
      <div className="campaign-planner__header">
        <h2>Let's Plan Your Campaign</h2>
        <p className="subtitle">Start with some basic details and our AI will optimize your campaign strategy</p>
      </div>

      <form onSubmit={handleSubmit} className="campaign-form">
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="name">Campaign Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={campaignDetails.name}
              onChange={handleInputChange}
              placeholder="e.g., Q2 2024 Campaign"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="budget">Budget (₹)</label>
            <input
              type="number"
              id="budget"
              name="budget"
              value={campaignDetails.budget}
              onChange={handleInputChange}
              placeholder="Min: ₹10,000"
              min="10000"
              step="1000"
              required
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <div className="input-icon">
              <FontAwesomeIcon icon={faCalendar} />
              <input
                type="date"
                id="startDate"
                name="startDate"
                value={campaignDetails.startDate}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="endDate">End Date</label>
            <div className="input-icon">
              <FontAwesomeIcon icon={faCalendar} />
              <input
                type="date"
                id="endDate"
                name="endDate"
                value={campaignDetails.endDate}
                onChange={handleInputChange}
                min={campaignDetails.startDate}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="targetAudience">Target Audience</label>
          <input
            type="text"
            id="targetAudience"
            name="targetAudience"
            value={campaignDetails.targetAudience}
            onChange={handleInputChange}
            placeholder="e.g., Young professionals, 25-35, metro cities"
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="objectives">Campaign Objectives</label>
          <textarea
            id="objectives"
            name="objectives"
            value={campaignDetails.objectives}
            onChange={handleInputChange}
            placeholder="e.g., Increase brand awareness, drive website traffic"
            required
          />
        </div>

        <div className="form-actions">
          <button type="button" className="secondary-button" onClick={onClose}>
            Cancel
          </button>
          <button type="submit" className="primary-button">
            Generate AI Campaign
          </button>
        </div>
      </form>
    </div>
  );
};

export default CampaignPlanner;
