import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../AuthContext';
import './Header.css';

function Header({ onLoginClick, isSidebarOpen, setIsSidebarOpen }) {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // Add search functionality here
  };

  return (
    <header className="header">
      <div className="header-left">
        <button 
          className="sidebar-toggle" 
          onClick={toggleSidebar}
          aria-label="Toggle menu"
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <Link to="/" className="logo-link">
          <h1>PlaceAd</h1>
        </Link>
      </div>

      <form className="search-form" onSubmit={handleSearch}>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search media spaces..."
            className="search-input"
          />
          <button type="submit" className="search-button">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </form>

      <div className="header-right">
        {user ? (
          <Link to="/profile" className="profile-link">
            <FontAwesomeIcon icon={faUser} />
            <span>{user.name || 'Profile'}</span>
          </Link>
        ) : (
          <button className="login-btn" onClick={onLoginClick}>
            <FontAwesomeIcon icon={faUser} />
            <span>Login</span>
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;
