import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faComment, 
    faPaperPlane, 
    faTimes, 
    faRobot,
    faSpinner 
} from '@fortawesome/free-solid-svg-icons';
import './ChatBot.css';

const ChatBot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([
        {
            type: 'bot',
            content: '👋 Hi! I\'m PlaceAI, your AI advertising assistant powered by advanced machine learning.',
            typing: false
        },
        {
            type: 'bot',
            content: 'I can help you discover ad spaces, get pricing information, and connect with our sales team. How can I assist you today?',
            options: ['Find Ad Spaces', 'Pricing Info', 'List My Space', 'Talk to Sales'],
            typing: false
        }
    ]);
    const [userInfo, setUserInfo] = useState(null);
    const [currentInput, setCurrentInput] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const simulateTyping = async (message, delay = 30) => {
        setIsTyping(true);
        return new Promise(resolve => {
            setTimeout(() => {
                setIsTyping(false);
                resolve();
            }, message.length * delay);
        });
    };

    const addBotMessage = async (messageContent, options = null) => {
        setIsTyping(true);
        setMessages(prev => [...prev, { type: 'bot', content: '', typing: true }]);
        
        await simulateTyping(messageContent);
        
        setMessages(prev => [
            ...prev.slice(0, -1),
            {
                type: 'bot',
                content: messageContent,
                options: options,
                typing: false
            }
        ]);
        setIsTyping(false);
    };

    const handleOptionClick = async (option) => {
        setMessages(prev => [...prev, { type: 'user', content: option }]);

        if (!userInfo && option !== 'Find Ad Spaces') {
            await addBotMessage('I\'d be happy to help! To provide you with the most accurate information, could you share your contact details?', null);
            setTimeout(() => {
                setMessages(prev => [...prev, {
                    type: 'bot',
                    content: 'This helps me personalize recommendations and allows our team to follow up with detailed information.',
                    form: {
                        fields: ['name', 'email', 'phone'],
                        purpose: option
                    },
                    typing: false
                }]);
            }, 1000);
            return;
        }

        switch (option) {
            case 'Find Ad Spaces':
                await handleFindAdSpaces();
                break;
            case 'Pricing Info':
                await handlePricingInfo();
                break;
            case 'List My Space':
                await handleListSpace();
                break;
            case 'Talk to Sales':
                await handleTalkToSales();
                break;
            default:
                break;
        }
    };

    const handleFindAdSpaces = async () => {
        try {
            await addBotMessage('🔍 Searching our database for the best ad spaces...', null);
            
            const response = await fetch('/api/sites/featured');
            const data = await response.json();
            
            if (data.sites && data.sites.length > 0) {
                const sitesList = data.sites.slice(0, 3).map(site => 
                    `📍 ${site.name} - ${site.location} (${site.type})`
                ).join('\\n');
                
                await addBotMessage(
                    `I've found some excellent options for you:\\n${sitesList}\\n\\nWould you like to see more options or get personalized recommendations?`,
                    ['See More', 'Get Recommendations', 'Talk to Sales']
                );
            }
        } catch (error) {
            console.error('Error fetching ad spaces:', error);
            await addBotMessage(
                '🤔 I apologize, but I\'m having trouble accessing the ad spaces information. Would you like to talk to our sales team instead?',
                ['Talk to Sales', 'Try Again']
            );
        }
    };

    const handlePricingInfo = async () => {
        await addBotMessage(
            '💡 Our advertising spaces are priced based on several factors including location, size, and duration. Let me help you find the perfect option for your budget.',
            ['See Price Ranges', 'Get Custom Quote', 'Talk to Sales']
        );
    };

    const handleListSpace = async () => {
        await addBotMessage(
            '🌟 That\'s great! I\'d love to help you list your advertising space. Our platform reaches thousands of potential advertisers.',
            ['Get Started Now', 'Learn More', 'Talk to Sales']
        );
    };

    const handleTalkToSales = async () => {
        await addBotMessage(
            '📞 I\'ll connect you with our expert sales team. They typically respond within 2 hours during business hours.',
            ['Schedule Call', 'Send Email Instead']
        );
    };

    const handleFormSubmit = async (formData) => {
        try {
            const response = await fetch('http://localhost:5001/api/leads', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...formData,
                    source: 'ai_chatbot'
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setUserInfo(formData);
                await addBotMessage(
                    `✨ Thanks ${formData.name}! I've saved your information securely. How can I assist you further?`,
                    ['Find Ad Spaces', 'Get Custom Quote', 'Schedule Call']
                );
            } else {
                throw new Error(data.message || 'Failed to save contact information');
            }
        } catch (error) {
            console.error('Error saving lead:', error);
            await addBotMessage(
                '❌ I apologize, but I encountered an error saving your information. Please try again or refresh the page.',
                ['Try Again', 'Talk to Sales']
            );
        }
    };

    const handleSendMessage = async () => {
        if (!currentInput.trim()) return;

        const userMessage = currentInput.trim();
        setCurrentInput('');
        setMessages(prev => [...prev, { type: 'user', content: userMessage }]);

        await addBotMessage(
            '🤔 Let me help you with that. Would you like to:',
            ['Find Ad Spaces', 'Get Custom Quote', 'Talk to Sales']
        );
    };

    return (
        <div className="chatbot-container">
            {!isOpen && (
                <button className="chat-trigger" onClick={() => setIsOpen(true)}>
                    <FontAwesomeIcon icon={faRobot} className="robot-icon" />
                </button>
            )}
            
            {isOpen && (
                <div className="chat-window">
                    <div className="chat-header">
                        <div className="header-content">
                            <FontAwesomeIcon icon={faRobot} className="robot-icon" />
                            <div className="header-text">
                                <h3>PlaceAI Assistant</h3>
                                <span className="ai-status">AI-Powered • Always Learning</span>
                            </div>
                        </div>
                        <button className="close-button" onClick={() => setIsOpen(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    
                    <div className="chat-messages">
                        {messages.map((message, index) => (
                            <div key={index} className={`message ${message.type} ${message.typing ? 'typing' : ''}`}>
                                {message.type === 'bot' && (
                                    <div className="bot-indicator">
                                        <FontAwesomeIcon icon={faRobot} />
                                    </div>
                                )}
                                <div className="message-content">
                                    {message.typing ? (
                                        <div className="typing-indicator">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    ) : (
                                        message.content
                                    )}
                                </div>
                                
                                {message.options && !message.typing && (
                                    <div className="message-options">
                                        {message.options.map((option, optIndex) => (
                                            <button
                                                key={optIndex}
                                                onClick={() => handleOptionClick(option)}
                                                className="ai-button"
                                            >
                                                {option}
                                            </button>
                                        ))}
                                    </div>
                                )}
                                
                                {message.form && !message.typing && (
                                    <form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            const formData = {
                                                name: e.target.name.value,
                                                email: e.target.email.value,
                                                phone: e.target.phone.value,
                                                interest: message.form.purpose
                                            };
                                            handleFormSubmit(formData);
                                        }}
                                        className="contact-form"
                                    >
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Your Name"
                                            required
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Your Email"
                                            required
                                        />
                                        <input
                                            type="tel"
                                            name="phone"
                                            placeholder="Your Phone"
                                            required
                                        />
                                        <button type="submit" className="ai-button">
                                            Submit
                                        </button>
                                    </form>
                                )}
                            </div>
                        ))}
                        {isTyping && (
                            <div className="message bot">
                                <div className="bot-indicator">
                                    <FontAwesomeIcon icon={faRobot} />
                                </div>
                                <div className="typing-indicator">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        )}
                        <div ref={messagesEndRef} />
                    </div>
                    
                    <div className="chat-input">
                        <input
                            type="text"
                            value={currentInput}
                            onChange={(e) => setCurrentInput(e.target.value)}
                            placeholder="Ask me anything..."
                            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        />
                        <button onClick={handleSendMessage} className="ai-button">
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatBot;
