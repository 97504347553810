import { useState, useEffect } from 'react';

const useExitIntent = (options = {}) => {
    const { threshold = 20, timeToWait = 1000 } = options;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        let timer;
        const leadCaptured = localStorage.getItem('leadCaptured');
        
        // if (leadCaptured === 'true') {
        //     return;
        // }

        const handleMouseLeave = (e) => {
            // Only trigger if mouse moves above the threshold
            if (e.clientY <= threshold) {
                // Set a timeout to prevent triggering when user just briefly moves above viewport
                timer = setTimeout(() => {
                    setIsVisible(true);
                }, timeToWait);
            }
        };

        const handleMouseEnter = () => {
            clearTimeout(timer);
        };

        // Add event listeners
        document.addEventListener('mouseleave', handleMouseLeave);
        document.addEventListener('mouseenter', handleMouseEnter);

        // Mobile/Tablet detection
        let startY;
        const handleTouchStart = (e) => {
            startY = e.touches[0].pageY;
        };

        const handleTouchMove = (e) => {
            const currentY = e.touches[0].pageY;
            // If user is scrolling up rapidly near the top
            if (startY > currentY && window.scrollY < 100) {
                setIsVisible(true);
            }
        };

        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);

        return () => {
            // Cleanup
            document.removeEventListener('mouseleave', handleMouseLeave);
            document.removeEventListener('mouseenter', handleMouseEnter);
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            clearTimeout(timer);
        };
    }, [threshold, timeToWait]);

    return [isVisible, setIsVisible];
};

export default useExitIntent;
