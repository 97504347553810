// src/components/LoginModal.js
import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../AuthContext'; // Import AuthContext
import api from '../api'; // Import api
import './LoginModal.css';

function LoginModal({ onClose, onSwitchToSignUp }) {
  const { login } = useContext(AuthContext); // Destructure login function
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState(''); // State to handle errors
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    // Clear error when user starts typing
    if (error) setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isMounted) return;
    
    setError('');
    setIsLoading(true);

    try {
        const response = await api.post('/auth/login', formData);
        
        if (response.data.success && isMounted) {
            // Store token
            localStorage.setItem('token', response.data.token);
            
            // Store user data
            localStorage.setItem('user', JSON.stringify(response.data.user));
            
            // Update context
            login(response.data.user);
            
            // Close modal
            onClose();
        }
    } catch (err) {
        console.error('Login error:', err);
        if (isMounted) {
            setError(
                err.response?.data?.message || 
                'Invalid email or password'
            );
            setIsLoading(false);
        }
    } finally {
        setIsLoading(false);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target.className === 'modal-overlay') {
      onClose();
    }
  };

  return (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal-content">
          <button className="close-btn" onClick={onClose}>×</button>
          
          <h2>Welcome Back</h2>
          
          {error && <div className="error-message">{error}</div>}
          
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                required
                autoComplete="email"
              />
            </div>
            
            <div className="form-group">
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                required
                autoComplete="current-password"
              />
            </div>
            
            <button 
              type="submit" 
              className="submit-btn"
              disabled={isLoading}
            >
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </form>

          <p className="switch-text">
            Don't have an account?{' '}
            <button 
              className="switch-btn" 
              onClick={onSwitchToSignUp}
            >
              Sign Up
            </button>
          </p>
        </div>
      </div>
  );
}

export default LoginModal;
