import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './FAQ.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What is PlaceAd and how does it work?",
            answer: "PlaceAd is an AI-powered marketing platform that helps businesses find and book advertising spaces. Our platform uses advanced algorithms to match your campaign goals with the most effective advertising locations, providing real-time analytics and optimization suggestions."
        },
        {
            question: "What types of advertising media can I find on PlaceAd?",
            answer: "We offer a wide range of advertising media including traditional billboards, digital displays, transit media, airport advertising, mall media, street furniture, cinema advertising, and more. Our platform covers both traditional and digital advertising spaces across various locations."
        },
        {
            question: "How does the AI-powered campaign planning work?",
            answer: "Our AI system analyzes your campaign objectives, target audience, budget, and timeline to recommend the most effective media placements. It considers factors like location analytics, audience demographics, historical performance data, and real-time availability to optimize your campaign strategy."
        },
        {
            question: "Can I list my advertising space on PlaceAd?",
            answer: "Yes! Media owners can easily list their advertising spaces on our platform. Simply register as a media owner, provide details about your advertising space, and start receiving booking requests. We verify all listings to ensure quality and authenticity."
        },
        {
            question: "How do I track my campaign's performance?",
            answer: "PlaceAd provides comprehensive analytics and reporting tools. Track key metrics like impressions, engagement rates, and ROI in real-time through our dashboard. Our AI also provides insights and recommendations to optimize your campaign performance."
        },
        {
            question: "What makes PlaceAd different from traditional media buying?",
            answer: "PlaceAd combines AI technology with traditional media buying to provide a more efficient, data-driven approach. We offer real-time availability, transparent pricing, automated campaign optimization, and comprehensive performance analytics - all in one platform."
        }
    ];

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faq-section">
            <div className="faq-container">
                <h2>Frequently Asked Questions</h2>
                <div className="faq-grid">
                    {faqs.map((faq, index) => (
                        <div 
                            key={index} 
                            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            <div className="faq-question">
                                <h3>{faq.question}</h3>
                                <FontAwesomeIcon 
                                    icon={activeIndex === index ? faChevronUp : faChevronDown} 
                                    className="faq-icon"
                                />
                            </div>
                            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
