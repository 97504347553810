// src/utils/api.js

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

/**
 * Constructs an API URL with the correct prefix and path
 * @param {string} endpoint - The API endpoint (e.g., 'medias', 'profile', etc.)
 * @returns {string} The complete API URL
 */
export const getApiUrl = (endpoint) => {
    if (!endpoint) {
        throw new Error('Endpoint is required');
    }
    
    // Split endpoint into path and query string
    const [path, query] = endpoint.split('?');
    
    // Clean the path - remove leading/trailing slashes
    const cleanPath = path.replace(/^\/+|\/+$/g, '');
    
    // Construct the base URL without adding /api since it's already in the response
    const baseUrl = `${API_BASE_URL}/${cleanPath}`;
    
    // Add query string if present
    const finalUrl = query ? `${baseUrl}?${query}` : baseUrl;
    
    // Log the URL construction for debugging
    console.log('[API URL Construction]', {
        input: endpoint,
        path,
        query: query || 'none',
        cleanPath,
        baseUrl: API_BASE_URL,
        final: finalUrl
    });
    
    return finalUrl;
};

export const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
