// Google Analytics 4 utility functions
export const GA_TRACKING_ID = 'G-X21S5S2TNH';

// Initialize GA4
export const initGA = () => {
  console.log('Initializing GA4...'); // Debug log
  
  // Check if GA script is loaded
  if (typeof window !== 'undefined') {
    console.log('GA Script status:', {
      dataLayerExists: !!window.dataLayer,
      gtagExists: !!window.gtag,
      gaExists: !!window.ga
    });
  }

  if (typeof window !== 'undefined' && !window.ga_initialized) {
    window.ga_initialized = true;
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    gtag('js', new Date());
    
    // Configure GA4 with enhanced measurement settings
    gtag('config', GA_TRACKING_ID, {
      debug_mode: true, // Enable debug mode
      send_page_view: true
    });
    
    // Verify configuration
    console.log('GA4 Configuration:', {
      measurementId: GA_TRACKING_ID,
      dataLayer: window.dataLayer,
      gtagFunction: !!window.gtag
    });
    
    console.log('GA4 initialized successfully'); // Debug log
  }
};

// Track page views with enhanced data
export const pageview = (url) => {
  console.log('Tracking pageview:', url); // Debug log
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
      page_title: document.title,
      page_location: window.location.href
    });
  } else {
    console.warn('gtag not available for pageview'); // Debug log
  }
};

// Track user session start
export const trackSessionStart = () => {
  console.log('Starting new session'); // Debug log
  const sessionId = Date.now().toString();
  sessionStorage.setItem('session_id', sessionId);
  event({
    action: 'session_start',
    category: 'User Session',
    label: sessionId
  });
};

// Track events
export const event = ({ action, category, label, value }) => {
  console.log('Tracking event:', { action, category, label, value }); // Debug log
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  } else {
    console.warn('gtag not available for event tracking'); // Debug log
  }
};
