import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faFacebookF, 
    faTwitter, 
    faInstagram, 
    faLinkedinIn 
} from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>PlaceAd</h3>
                    <p className="footer-description">
                        Your one-stop destination for discovering and booking premium advertising spaces across India.
                    </p>
                    <div className="social-links">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </a>
                    </div>
                </div>

                <div className="footer-section">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/view-sites">Browse Media</Link></li>
                        <li><Link to="/add-site">List Your Space</Link></li>
                        <li><Link to="/agency-registration">Register Agency</Link></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>Media Types</h4>
                    <ul>
                        <li><Link to="/view-sites?type=billboard">Traditional Billboards</Link></li>
                        <li><Link to="/view-sites?type=digital-billboard">Digital Billboards</Link></li>
                        <li><Link to="/view-sites?type=transit">Transit Media</Link></li>
                        <li><Link to="/view-sites?type=bus-shelter">Bus Shelters</Link></li>
                        <li><Link to="/view-sites?type=metro">Metro & Railway</Link></li>
                        <li><Link to="/view-sites?type=airport">Airport Media</Link></li>
                        <li><Link to="/view-sites?type=mall">Shopping Mall Media</Link></li>
                        <li><Link to="/view-sites?type=street">Street Furniture</Link></li>
                        <li><Link to="/view-sites?type=digital-signage">Digital Signage</Link></li>
                        <li><Link to="/view-sites?type=cinema">Cinema Advertising</Link></li>
                        <li><Link to="/view-sites?type=office">Office Complex Media</Link></li>
                        <li><Link to="/view-sites?type=residential">Residential Media</Link></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h4>Contact Us</h4>
                    <ul className="contact-info">
                        <li>
                            <span className="contact-label">Email:</span>
                            <a href="mailto:contact@placead.ai">contact@placead.ai</a>
                        </li>
                        <li>
                            <span className="contact-label">Phone:</span>
                            <a href="tel:+15551234567">+1 (555) 123-4567</a>
                        </li>
                        <li>
                            <span className="contact-label">Address:</span>
                            <address>
                                123 Innovation Drive<br />
                                San Francisco, CA 94105
                            </address>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <p>&copy; {new Date().getFullYear()} PlaceAd. All rights reserved.</p>
                    <div className="footer-links">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/sitemap">Sitemap</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
