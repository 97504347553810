// AI-powered package recommendations generator

const validateInput = (mediaType, budget) => {
    if (!mediaType || !budget) {
        throw new Error('Media type and budget are required');
    }
    if (budget <= 0) {
        throw new Error('Budget must be greater than 0');
    }
};

const calculateMediaCosts = (mediaType, budget) => {
    try {
        validateInput(mediaType, budget);
        
        const costs = {
            base: {
                billboards: 100000,
                radio: 50000,
                print: 75000,
                digital: 60000,
                social: 40000,
                display: 35000
            }
        };

        if (!costs.base[mediaType]) {
            throw new Error(`Invalid media type: ${mediaType}`);
        }

        return {
            baseCost: costs.base[mediaType],
            totalCost: costs.base[mediaType] + (budget * 0.1)
        };
    } catch (error) {
        console.error('Error in calculateMediaCosts:', error);
        throw error;
    }
};

const generateOutcomeMetrics = (pkg) => {
    const baseMetrics = {
        reach: 0,
        engagement: 0,
        conversion: 0,
        roi: 0
    };

    pkg.mediaAllocations.forEach(({ type, allocation }) => {
        switch (type) {
            case 'billboards':
                baseMetrics.reach += allocation * 10000;
                baseMetrics.engagement += allocation * 100;
                break;
            case 'radio':
                baseMetrics.reach += allocation * 8000;
                baseMetrics.engagement += allocation * 200;
                break;
            case 'print':
                baseMetrics.reach += allocation * 5000;
                baseMetrics.engagement += allocation * 150;
                break;
            case 'digital':
                baseMetrics.reach += allocation * 15000;
                baseMetrics.engagement += allocation * 500;
                break;
            case 'social':
                baseMetrics.reach += allocation * 20000;
                baseMetrics.engagement += allocation * 800;
                break;
            case 'display':
                baseMetrics.reach += allocation * 12000;
                baseMetrics.engagement += allocation * 300;
                break;
            default:
                break;
        }
    });

    baseMetrics.conversion = baseMetrics.engagement * 0.02;
    baseMetrics.roi = (baseMetrics.conversion * 1000) / pkg.totalCost;

    return baseMetrics;
};

const getPackageFeatures = (tierName) => {
    const features = {
        basic: [
            'Basic media mix optimization',
            'Standard reporting dashboard',
            'Monthly performance reviews'
        ],
        premium: [
            'Advanced media mix optimization',
            'Real-time reporting dashboard',
            'Weekly performance reviews',
            'A/B testing capabilities'
        ],
        enterprise: [
            'AI-powered media mix optimization',
            'Custom reporting dashboard',
            'Daily performance reviews',
            'Advanced A/B testing',
            'Dedicated campaign manager'
        ]
    };

    return features[tierName] || features.basic;
};

const generatePackages = ({ budget, mediaTypes, goal }) => {
    try {
        const tiers = ['basic', 'premium', 'enterprise'];
        const allocations = {
            basic: 0.8,
            premium: 0.9,
            enterprise: 1.0
        };

        return tiers.map(tier => {
            const tierBudget = budget * allocations[tier];
            const mediaAllocations = mediaTypes.map(type => {
                try {
                    const { totalCost } = calculateMediaCosts(type, tierBudget);
                    return {
                        type,
                        allocation: tierBudget / mediaTypes.length,
                        cost: totalCost
                    };
                } catch (error) {
                    console.error(`Error generating package for ${type}:`, error);
                    return null;
                }
            }).filter(Boolean);

            if (mediaAllocations.length === 0) {
                return null;
            }

            const totalCost = mediaAllocations.reduce((sum, { cost }) => sum + cost, 0);
            const pkg = {
                name: `${tier.charAt(0).toUpperCase() + tier.slice(1)} Package`,
                tier,
                totalCost,
                mediaAllocations,
                features: getPackageFeatures(tier)
            };

            pkg.metrics = generateOutcomeMetrics(pkg);
            return pkg;
        }).filter(Boolean);
    } catch (error) {
        console.error('Error generating packages:', error);
        return [];
    }
};

export { generatePackages };
