import React from 'react';
import { Link } from 'react-router-dom';
import './LegalPages.css';

function Sitemap() {
    return (
        <div className="legal-page">
            <div className="legal-container">
                <h1>Sitemap</h1>
                
                <section>
                    <h2>Main Pages</h2>
                    <ul className="sitemap-list">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About Us</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </section>

                <section>
                    <h2>Media Services</h2>
                    <ul className="sitemap-list">
                        <li><Link to="/view-sites">Browse Media</Link></li>
                        <li><Link to="/add-site">List Your Space</Link></li>
                        <li><Link to="/agency-registration">Register Agency</Link></li>
                    </ul>
                </section>

                <section>
                    <h2>Campaign Management</h2>
                    <ul className="sitemap-list">
                        <li><Link to="/campaign-planner">Campaign Planner</Link></li>
                        <li><Link to="/analytics">Analytics Dashboard</Link></li>
                        <li><Link to="/reports">Reports</Link></li>
                    </ul>
                </section>

                <section>
                    <h2>Media Types</h2>
                    <ul className="sitemap-list">
                        <li><Link to="/view-sites?type=billboard">Billboards</Link></li>
                        <li><Link to="/view-sites?type=transit">Transit Media</Link></li>
                        <li><Link to="/view-sites?type=digital">Digital Screens</Link></li>
                        <li><Link to="/view-sites?type=airport">Airport Media</Link></li>
                    </ul>
                </section>

                <section>
                    <h2>Legal & Support</h2>
                    <ul className="sitemap-list">
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms">Terms of Service</Link></li>
                        <li><Link to="/faq">FAQ</Link></li>
                        <li><Link to="/support">Support</Link></li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default Sitemap;
