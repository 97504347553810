import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faUsers,
  faMoneyBillWave,
  faChartPie,
  faGlobe,
  faChartBar,
  faEye,
  faHandshake
} from '@fortawesome/free-solid-svg-icons';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import './Analytics.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
  const history = useHistory();

  const metrics = [
    {
      title: "Campaign Performance",
      value: "85%",
      trend: "+5%",
      icon: faChartLine,
      description: "Average campaign success rate"
    },
    {
      title: "Audience Reach",
      value: "2.5M",
      trend: "+12%",
      icon: faUsers,
      description: "Monthly active users"
    },
    {
      title: "ROI",
      value: "3.2x",
      trend: "+0.4x",
      icon: faMoneyBillWave,
      description: "Return on investment"
    },
    {
      title: "Channels",
      value: "12+",
      trend: "+2",
      icon: faChartPie,
      description: "Active marketing channels"
    }
  ];

  const insights = [
    {
      title: "Geographic Impact",
      icon: faGlobe,
      description: "25+ countries reached, strong in metro areas",
      stats: "25+ Countries"
    },
    {
      title: "Channel Performance",
      icon: faChartBar,
      description: "40% higher digital engagement rates",
      stats: "40% Higher Engagement"
    },
    {
      title: "Audience Behavior",
      icon: faEye,
      description: "Peak engagement: 6-9 PM, 65% mobile",
      stats: "65% Mobile Traffic"
    },
    {
      title: "Partnership Success",
      icon: faHandshake,
      description: "2.8x better results with influencers",
      stats: "2.8x Better Results"
    }
  ];

  const lineData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Performance',
        data: [65, 72, 78, 75, 82, 85],
        borderColor: '#4158D0',
        backgroundColor: 'rgba(65, 88, 208, 0.1)',
        tension: 0.4,
      },
    ],
  };

  const doughnutData = {
    labels: ['Digital', 'TV', 'Radio', 'Print', 'Outdoor'],
    datasets: [
      {
        data: [35, 25, 15, 15, 10],
        backgroundColor: [
          '#4158D0',
          '#C850C0',
          '#ffbb11',
          '#ff6b6b',
          '#4cd964',
        ],
      },
    ],
  };

  const barData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
      {
        label: 'Engagement',
        data: [4200, 4500, 4800, 4600, 5200, 5800, 5400],
        backgroundColor: 'rgba(200, 80, 192, 0.8)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div className="analytics-page">
      <div className="analytics-header">
        <h1>Campaign Analytics</h1>
        <p>Performance insights across all channels</p>
      </div>

      <section className="metrics-grid">
        {metrics.map((metric, index) => (
          <div key={index} className="metric-card">
            <div className="metric-icon">
              <FontAwesomeIcon icon={metric.icon} />
            </div>
            <div className="metric-content">
              <h3>{metric.title}</h3>
              <div className="metric-numbers">
                <span className="metric-value">{metric.value}</span>
                <span className={`metric-trend ${metric.trend.startsWith('+') ? 'positive' : 'negative'}`}>
                  {metric.trend}
                </span>
              </div>
              <p className="metric-description">{metric.description}</p>
            </div>
          </div>
        ))}
      </section>

      <section className="charts-section">
        <div className="chart-container">
          <h3>Performance Trend</h3>
          <div className="chart-wrapper">
            <Line data={lineData} options={options} />
          </div>
        </div>
        
        <div className="chart-container">
          <h3>Channel Distribution</h3>
          <div className="chart-wrapper">
            <Doughnut data={doughnutData} options={options} />
          </div>
        </div>
        
        <div className="chart-container">
          <h3>Weekly Engagement</h3>
          <div className="chart-wrapper">
            <Bar data={barData} options={options} />
          </div>
        </div>
      </section>

      <section className="insights-section">
        <h2>Key Insights</h2>
        <div className="insights-grid">
          {insights.map((insight, index) => (
            <div key={index} className="insight-card">
              <div className="insight-header">
                <FontAwesomeIcon icon={insight.icon} className="insight-icon" />
                <h3>{insight.title}</h3>
              </div>
              <p className="insight-description">{insight.description}</p>
              <div className="insight-stats">{insight.stats}</div>
            </div>
          ))}
        </div>
      </section>

      <section className="analytics-cta">
        <h2>Ready to Optimize?</h2>
        <p>Plan your next campaign with AI-driven insights</p>
        <button className="cta-button" onClick={() => history.push('/campaign-planning')}>
          Start Planning
        </button>
      </section>
    </div>
  );
};

export default Analytics;
